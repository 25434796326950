<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card p-fluid">
				<div class="p-d-flex p-jc-between ">
					<div class="p-col-9"> 
						<h3 class="">New Invoice</h3>
					</div>
					<div class="p-col-3"> 
						<Button label="Save Invoice" class="p-mr-2 p-mb-2 p-button-success" @click="saveInvoiceDialog"/>
					</div>
				</div>
				<div class="p-grid">
					<div class="p-field p-col-6">
						<label for="group">Product group</label>
						<Dropdown id="group" v-model="product.product_group" :options="options.product_group"  optionLabel="" optionValue="" placeholder="Select One" :editable="true" @change="getProductsUpdate" />
					</div>
					<div class="p-field p-col-6">
						<label for="type">Product type</label>
						<Dropdown id="type" v-model="product.product_type" :options="options.product_type" optionLabel="" placeholder="Select One" :editable="true" @change="getProductsUpdate"/>
					</div>
				</div>
				<div class="p-grid">
					<div class="p-field p-col-6">
						<label for="option">Option</label>
						<Dropdown id="option" v-model="product.product_option" :options="options.product_option" optionLabel="" placeholder="Select One" :editable="true" @change="getProductsUpdate"/>
					</div>
					<div class="p-field p-col-6">
						<label for="hscode">HS code</label>
						<Dropdown id="hscode" v-model="product.hs_code" :options="options.hs_code" optionLabel="" placeholder="Select One" :editable="true"/>
					</div>
				</div>
				<div class="p-grid">
					<div class="p-field p-col-12 p-md-6">
						<label for="width">Product width</label>
						<InputNumber modelvalue="0" v-model="product.product_width" suffix=" mm" @change="getProductsUpdate"/>
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label for="thickness">Product thickness</label>
						<InputNumber modelvalue="0" v-model="product.product_thickness" suffix=" mm" @change="getProductsUpdate" />
					</div>
				</div>
				<div class="p-grid">
					<div class="p-field p-col-12 p-md-6">
						<label for="length">Product length</label>
						<InputNumber modelvalue="0" v-model="product.product_length" suffix=" mm" />
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label for="name">Product name</label>
						<Dropdown id="name" v-model="product.product_code" :options="options.name" optionLabel="" placeholder="Select One" required="true" autofocus :class="{'p-invalid': submitted && !product.product_code}"/>
						<small class="p-invalid" v-if="submitted && !product.product_code">Product name is required.</small>
					</div>
				</div>
				<div class="p-grid">
					<div class="p-field p-col-12 p-md-6">
						<label for="discount">Discount</label>
						<InputNumber modelvalue="0" v-model="product.discount" :max="20"/>
					</div>
					<div class="p-field p-col-12 p-md-6">
						<label for="quantity">Quantity</label>
						<InputNumber modelvalue="0" v-model="product.quantity"/>
					</div>
				</div>
				<div class="p-grid">				
					<div class="p-col-3">
						<Button label="Add" class="p-mr-2 p-mb-2" @click="addToTable" v-show="!editProduct"/>						
						<Button label="Save" class="p-mr-2 p-mb-2 p-button-success" @click="saveEdit" v-show="editProduct"/>
					</div>
				</div>
			</div>
		</div>	
		<div class="">
			<DataTable :value="products" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedProduct"
                        :filters="filters1" :loading="loading1">
					<template #header>
						<div class="table-header">
							List of Products
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global']" placeholder="Global Search" />
                            </span>
						</div>
					</template>
					<template #empty>
						No products found.
					</template>
					<template #loading>
						Loading products data. Please wait.
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3em"></Column>
					<Column field="group" header="Group" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.group}}
						</template>
					</Column>
					<Column field="type" header="type" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.type}}
						</template>
					</Column>
					<Column field="option" header="Option" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.option}}</span>
						</template>
					</Column>
					<Column field="hscode" header="HS code" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.hscode}}</span>
						</template>
					</Column>
					<Column field="width" header="width" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.width}}</span>
						</template>
					</Column>
					<Column field="thickness" header="Thickness" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.thickness}}</span>
						</template>
					</Column>
					<Column field="length" header="Length" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.length}}</span>
						</template>
					</Column>
					<Column field="name" header="Name" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.name}}</span>
						</template>
					</Column>
					<Column field="discount" header="Discount" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.discount}}</span>
						</template>
					</Column>
					<Column field="quantity" header="Quantity" :sortable="true">
						<template #body="slotProps">
							<span>{{slotProps.data.quantity}}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-pencil" class="p-button-success p-button-rounded p-mr-2" @click="editRow(slotProps.data)"></Button>
							<Button type="button" icon="pi pi-trash" class="p-button-warning p-button-rounded" @click="deleteRow(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>
				
				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.product_code}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="companyDialog" :style="{width: '45%'}" header="Company Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6">
							<label for="name">Company name</label>
							<Dropdown id="name" v-model.trim="company.name" :options="options.companies" required="true" autofocus :editable="true" />
						</div>
						<div class="p-field p-col-12 p-md-6">
							<label for="expenses">Air Freight Tashkent and Other Expenses</label>
							<InputText id="expenses" v-model.trim="company.expenses" required="true" autofocus />
						</div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="parent">Shipment From</label>
                            <InputText id="parent" v-model="company.shipment" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="payment">Payment Term</label>
                            <InputText id="payment" v-model="company.payment" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="currency">Currency</label>
                            <Dropdown id="currency" v-model="company.currency" :options="options.currencyOptions" optionLabel="" placeholder="Select One" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="delivery">Delivery Time</label>
                            <InputText id="delivery" v-model="company.delivery" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="weight">Estimated Weight</label>
                            <InputText id="weight" v-model="company.weight" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="parent">Proforma Invoice No</label>
                            <InputText id="parent" v-model="company.invoicenum" />
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="parent">Reference No</label>
                            <InputText id="parent" v-model="company.reference" />
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                    
                    </div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideInvoiceDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveInvoice" />
					</template>
				</Dialog>

		</div>
	</div>
</template>

<script>
	import InvoiceService from "../service/InvoiceService"
	export default {
		data() {
			return {
				product: {product_group: "Belt"},
				options: {product_option:['open', 'endless'], currencyOptions:['USD', 'EUR'], product_group:[]},
				invoiceService: null,
				products:[],
				selectedProduct: null,
				filters1: {},
				loading1: false,
				deleteProductDialog: false,
				editProduct: false,
				submitted: false,
				companyDialog: false,
				company: {}
			}
		},

		created() {
			this.invoiceService = new InvoiceService();
			this.invoiceService.getOptions().then(data => {
				this.options.product_group = data.product_group;
				this.options.product_type = data.product_type;
				this.options.hs_code = data.hs_code;
			});
			// this.invoiceService.getProducts(this.product).then(data=> {
			// 	this.options.name = data
			// });
		}, 
		methods:{
			getProductsUpdate(){
				this.invoiceService.getProducts(this.product).then(data=> {
					this.options.name = data
				})
			},

			addToTable() {
				this.submitted = true;
				if (this.product.product_code){
					this.product.id = this.products.length>0 ? this.products[this.products.length-1].id+1 : 1
					this.products.push(this.product);
					this.submitted = false;
					this.product = {};
				}
			},
			
			editRow(row) {
				console.log(row)
				this.product = row
				this.editProduct = true
			},

			saveEdit(){
				this.submitted = true;
				if (this.product.product_code){
					this.products = this.products.filter(val => val.id !== this.product.id);
					this.products.push(this.product)
					this.editProduct = false
					this.submitted = false;
					this.product = {}
				}
			},

			deleteRow(row) {
				this.product = row
				this.deleteProductDialog = true
			},

			deleteProduct(){
				this.products = this.products.filter(val => val.id !== this.product.id);
				this.deleteProductDialog = false;
				this.product = {};
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
			},

			saveInvoiceDialog(){
				this.companyDialog = true;
				this.invoiceService.getCompanies().then(data=> {
				this.options.companies = data.company_name
			});
				
			}, 

			hideInvoiceDialog(){
				this.companyDialog = false;
			},
			saveInvoice(){
				this.companyDialog = false;
			}

		}

	}
</script>

<style scoped lang="scss">
::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

.table-header {
	display: flex;
	justify-content: space-between;
}
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}
			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>