import axios from 'axios'
import FormData from 'form-data'
axios.defaults.withCredentials = true;

export default class InvoiceService {

  getOptions() {
    return axios.get('https://advantex.uz/invoice/fetch').then(response => response.data, (error) => {
      console.log(error);
    });
  }

  async getProducts(arr) {
    // return axios.get('https://advantex.uz/invoice/get', {params:{data_ar: arr}}).then(response => response.data, (error) => {
    //         console.log(error);
    //       });

    var data = new FormData();
    var res = null;
    if (arr.product_group) {
      data.append('product_group', arr.product_group);
    }
    if (arr.product_type) {
      data.append('product_type', arr.product_type ? arr.product_type : "");
    }
    if (arr.product_option) {
      data.append('product_option', arr.product_option ? arr.product_option : "");
    }
    if (arr.product_width) {
      data.append('product_width', arr.product_width ? arr.product_width : "");
    }
    if (arr.product_thickness) {
      data.append('product_thickness', arr.product_thickness ? arr.product_thickness : "");
    }
    var config = {
      method: 'post',
      url: 'https://advantex.uz/invoice/get',
      data: data
    };

    await axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        res = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return res;
  }

  getCompanies() {
    return axios.get('https://advantex.uz/invoice/fetch2').then(response => response.data, (error) => {
      console.log(error);
    });
  }

}  
